import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import * as contractfile from "../helper/contractData";
import { toast } from "react-toastify";
import Button from "react-bootstrap-button-loader";
import * as constdata from "../hooks/constant";
import { parseUnits } from "@ethersproject/units";
import { AddressZero } from "@ethersproject/constants";
import { useAccount } from "wagmi";
import Web3 from "web3";
import {
  presale_address,
  presale_abi,
  busd_address,
  usdt_address,
  token_abi,
} from "../hooks/constant";
export default function Buy() {
  const context = useWeb3React();
  const { library } = context;
  const { address: account } = useAccount();
  const [loading, setLoading] = useState(false);
  const [isApprove, setIsApprove] = useState(true);
  const [paymenttype, setPaymenttype] = useState(false);
  const [busdapprove, setBusdapprove] = useState(false);
  const [usdtapprove, setUsdtapprove] = useState(false);
  const [userbal, setUserBal] = useState(0);
  const [refresh, setRefresh] = useState(new Date());
  const [UsdtoBnb, setUsdtoBnb] = useState(0);
  const [perDollarPrice, setPerDollarPrice] = useState(0);

  const [amount, setAmount] = useState("0.0");
  const [token, setToken] = useState("0.0");
  const [error_msg, setError_msg] = useState("");
  // const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const web3 = new Web3(Web3.givenProvider);
  useEffect(() => {
    const _contract = new web3.eth.Contract(presale_abi, presale_address);
    setContract(_contract);
    async function checkApprove() {
      try {
        // get chain id
        const chainId = await web3.eth.getChainId();

        if (chainId !== 56) {
          toast.error("Please connect to Binance Smart Chain");
          return false;
        }

        if (account && chainId === 56) {
          const busdconatrct = new web3.eth.Contract(token_abi, busd_address);
          const usdtconatrct = new web3.eth.Contract(token_abi, usdt_address);

          let busdcheck = await busdconatrct.methods
            .allowance(account, presale_address)
            .call();

          let usdtcheck = await usdtconatrct.methods
            .allowance(account, presale_address)
            .call();

          if (parseFloat(busdcheck) > 10000) {
            setBusdapprove(true);
          }

          if (parseFloat(usdtcheck) > 10000) {
            setUsdtapprove(true);
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    }

    checkApprove();
  }, [account]);

  useEffect(() => {
    async function getUserBal() {
      if (account && contract) {
        let usdtoBnb = await contract.methods.Usdtobnb().call();
        let pdp = await contract.methods.perDollarPrice().call();
        setUsdtoBnb(Number(web3.utils.fromWei(usdtoBnb.toString(), "ether")));
        setPerDollarPrice(Number(web3.utils.fromWei(pdp.toString(), "ether")));
        let userB = await contract.methods.ikeBalance(account).call();
        userB = web3.utils.fromWei(userB.toString(), "ether");
        setUserBal(userB);
      }
    }

    getUserBal();
  }, [refresh, account]);

  const handlePaymentChange = async (e) => {
    setPaymenttype(e.target.value);
    console.log(e.target.value);
    if (e.target.value === "1") {
      //&& e.target.value !== "3"
      if (usdtapprove === true) {
        setIsApprove(true);
      } else {
        setIsApprove(false);
      }
    } else if (e.target.value === "2") {
      if (busdapprove === true) {
        setIsApprove(true);
      } else {
        setIsApprove(false);
      }
    } else {
      setIsApprove(true);
    }
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    if (paymenttype !== "3") {
      if (isNaN(e.target.value)) {
        setToken(0);
        setError_msg("(please enter valid amount.)");
        return;
      } else if (parseFloat(e.target.value) === 0) {
        setToken(0);
        setError_msg("(please enter valid amount.)");
        return;
      } else if (parseFloat(e.target.value) < constdata.minInvest) {
        setError_msg(`amount must be greater ${constdata.minInvest} USD`);
        setToken(0);
        return;
      } else {
        setError_msg("");
        setToken(
          parseFloat((e.target.value * constdata.tokenPerUSD).toFixed(3))
        );
      }
    } else {
      if (isNaN(e.target.value)) {
        setToken(0);
        setError_msg("(please enter valid amount.)");
        return;
      } else if (parseFloat(e.target.value) === 0) {
        setToken(0);
        setError_msg("(please enter valid amount.)");
        return;
      } else {
        setError_msg("");
        setToken(
          parseFloat(
            e.target.value * constdata.usdtToBnb * constdata.tokenPerUSD
          ).toFixed(3)
        );
      }
    }
  };

  const handleBuynow = async (e) => {
    try {
      setLoading(true);
      if (account) {
        let addr = AddressZero;
        if (window.location.href.includes("?ref=")) {
          addr = window.location.href.substring(
            window.location.href.indexOf("=") + 1
          );
        }
        if (
          (paymenttype === "1" || paymenttype === "2") &&
          !isNaN(amount) &&
          amount > 0
        ) {
          let decimals = 18;
          if (paymenttype === "1") {
            decimals = 18;
          }

          let _value = parseUnits(amount, decimals);
          await contract.methods.buyfromToken(paymenttype, addr, _value).send({
            from: account,
          });
          setLoading(false);
          setIsApprove(true);
          setRefresh(new Date());
          window.location.reload();
        } else {
          await contract.methods.buyFromNative(addr).send({
            value: web3.utils.toWei(amount.toString(), "ether"),
            from: account,
          });
          setLoading(false);
          window.location.reload();
        }
      } else {
        toast.error("Please Connect Wallet !");
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error("Opps.. something went wrong !");
      window.location.reload();
    }
  };

  const handleApproveToken = async (e) => {
    try {
      setLoading(true);
      if (account) {
        if (paymenttype) {
          let contract;
          let decimals = 18;
          if (paymenttype === "1") {
            contract = new web3.eth.Contract(token_abi, usdt_address);
            decimals = 18;
          } else if (paymenttype === "2") {
            contract = new web3.eth.Contract(token_abi, busd_address);
          } else {
            toast.error("something went wrong ! please try again later");
            setLoading(false);
            setIsApprove(true);
            return false;
          }
          let _amount = parseUnits(amount, decimals);
          await contract.methods.approve(presale_address, _amount).send({
            from: account,
          });
          toast.success("success ! Your Last Transaction is Successfull.");
          setLoading(false);
          setIsApprove(true);
          if (paymenttype === "1") {
            setUsdtapprove(true);
          } else if (paymenttype === "2") {
            setBusdapprove(true);
          }

          //console.log({ response });
        } else {
          toast.error("Select payment type !");

          setLoading(false);
        }
      } else {
        toast.error("Please Connect Wallet !");
        setLoading(false);
      }
    } catch (err) {
      toast.error("Opps.. something went wrong !");
      setLoading(false);
    }
  };

  return (
    <>
      <div className="app-trade">
        <div className="app-trade__items grid">
          <div className="app-trade__item flex">
            <div className="app-trade__item-input">
              <div className="app-trade__token">
                <div className="form-check form-check-inline flex flex-row space-between">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    onChange={(e) => handlePaymentChange(e)}
                    id="inlineRadio1"
                    value="1"
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    <img src="../images/tether.png" alt="bnb-logo" />
                    USDT
                  </label>
                </div>
                <div className="form-check form-check-inline flex flex-row space-between">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    onChange={(e) => handlePaymentChange(e)}
                    id="inlineRadio2"
                    value="2"
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    <img src="../images/bnb-logo.png" alt="bnb-logo" />
                    BUSD
                  </label>
                </div>
                <div className="form-check form-check-inline flex flex-row space-between">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    onChange={(e) => handlePaymentChange(e)}
                    id="inlineRadio2"
                    value="3"
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    <img src="../images/bnb.png" alt="matic-logo" />
                    BNB
                  </label>
                </div>
              </div>
              <input
                type="text"
                style={{ border: "none", width: "100%" }}
                onChange={handleAmountChange}
                value={amount}
                className="app-trade__item-value"
                placeholder="0.0"
              />
            </div>
          </div>
          <div className="app-trade__item flex">
            <div className="app-trade__item-info flex flex--column">
              <h3 className="app-trade__token">
                <img src="../images/logo.png" alt="min-logo" />
                $SSE
              </h3>
              <p className="app-trade__balance">
                Balance <span id="myTokens">{userbal}</span> <br />
                SpaceShipEarth{" "}
              </p>
            </div>
            <div className="app-trade__item-input">
              <div className="app-trade__item-value">
                <span id="get">~{token}</span>
              </div>
            </div>
          </div>
          <small className="text-danger">{error_msg}</small>
        </div>
        {isApprove ? (
          <Button
            loading={loading}
            className="btn"
            style={{ textAlign: "center" }}
            onClick={handleBuynow}
          >
            Buy Now
          </Button>
        ) : (
          <Button
            loading={loading}
            className="btn"
            style={{ textAlign: "center" }}
            onClick={handleApproveToken}
          >
            Approve
          </Button>
        )}
        <p style={{
          color: "white",
          fontSize: "16px",
          textAlign: "center",
          marginTop: "12px",
          

        }}>Accessible to mobile phone users, this presale allows seamless purchasing of $SSE tokens through their wallet web browser, ensuring a secure transaction process.</p>
      </div>
    </>
  );
}
