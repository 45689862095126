import React, { useState, useEffect } from "react";

import Buy from "./Buy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import discord from "./image/discord.png";
import twitter from "./image/twitter.png";
import telegram from "./image/tg.png";
import { Web3Button } from "@web3modal/react";
import { useAccount } from "wagmi";
export default function Main() {
  const { address: account } = useAccount();
  const [referrallink, setReferrallink] = useState(
    "Please connect your wallet first (Metamask/Trustwallet)"
  );
  const [refcopy, setRefcopy] = useState(false);
  // const [addrcopy, setAddrcopy] = useState(false);
  let base_url = `${window.location.href}?ref=`;

  useEffect(() => {
    async function fetchData() {
      if (account) {
        setReferrallink(`${base_url}${account}`);
      } else {
        setReferrallink("Please connect your wallet first");
      }
    }
    fetchData();
  }, [account, base_url]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container-fluid">
          <a href="https://spaceshipearthtoken.com/" className="navbar-brand">
            <img
              src="../images/logo.png"
              alt="coin-67"
              style={{ width: "auto", height: "100px" }}
            />
          </a>
        </div>
      </nav>

      <main className="main mt-4">
        <section className="app section">
          <div className="app-container container flex flex--column">
            <div
              style={{
                textAlign: "center",
                fontSize: "17px",
                color: "#fff",
                marginBottom: "20px",
              }}
            >
              <Web3Button />
            </div>
            <div className="app__data-wrapper grid">
              <div className="app__data-container flex flex--column">
                {/* <h2 className="app__title">TOTAL SpaceShipEarth</h2> */}
                <div className="app__data-content">
                  <div className="app__value">
                    <span
                      className="saleqty"
                      style={{
                        textAlign: "center",
                        fontSize: "17px",
                        
                        //fontWeight: "bold",
                      }}
                    >
                      Presale supply
                    </span>
                    <br/>
                    <span
                      className="saleqty"
                      style={{
                        textAlign: "center",
                        fontSize: "17px",
                        //fontWeight: "bold",
                      }}
                    >
                      260,000,000,000,000 
                    </span>
                    
                    
                  </div>
                </div>
              </div>
              <div className="app__data-container flex flex--column">
                <h2 className="app__title">PRICE PER TOKEN</h2>
                <div className="app__data-content">
                  <div className="app__value">
                    1 USD ={" "}
                    <span
                      className="rate"
                      style={{
                        textAlign: "center",
                        fontSize: "17px",
                      }}
                    >
                      20000000 $SSE
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="app__data-wrapper grid"></div>
            {/* <div className="app__data-container flex flex--column">
              <h2 className="app__title mb-0">
                Airdrops will be on Pulse-Chain
              </h2>

            </div> */}
            <div className="app__data-container flex flex--column">
              {/* <Countdown
                key={Math.floor(Math.random() * 10 + 1)}
                date={countdowntime}
                renderer={whitelistcountdown}
              /> */}
              {/* <h2 className="app__title mb-0">
                Alternative Coins Eth, Avax, Matic, Fantom Etc, can be sent to
                the wallet address
              </h2>
              <CopyToClipboard text="0x6043c59C77f6bdd6fED7130cA68aBB4Dc1Fe88E2" onCopy={() => {setAddrcopy(true);
                                                                setTimeout(()=>{
                                                                  setAddrcopy(false);
                                                                },2000)}}>
                <div className="token-app btn">
                  0x6043c59C77f6bdd6fED7130cA68aBB4Dc1Fe88E2
                </div>
              </CopyToClipboard>
              {addrcopy && <span className="text-center">copied</span>} */}
            </div>
            <div className="app__data-container flex flex--column">
              <h1 className="text-center mb-5" style={{ color: "#fff" }}>
                Buy Token
              </h1>
              <Buy />
            </div>
          </div>
        </section>
        {/* <section className="app section">
          <div
            className="app-container container flex flex--column"
            style={{ marginTop: "5px!important" }}
          >
            <div
              style={{ textAlign: "center" }}
              className="app__data-container flex flex--column"
            >
              <h1 className="">BIO</h1>
              <br />
              <p>
                Token Name: Pinocchio Token symbol: Pinocchio Token is a
                cryptocurrency with blockchain with smart contract a payment
                system that can be sent from user to user on the peer-to-peer
                bitcoin network without the need for intermediaries.Transactions
                are verified by network nodes through cryptography and recorded
                in a public distributed ledger called a blockchain. The
                cryptocurrency was invented in 2018.
              </p>
              <br />
              <p>Token Price: $25</p>
              <p>Token Supply: 1 trillion </p>
              <p>Decimal:15</p>
            </div>
          </div>
        </section> */}
        <section className="app section">
          <div
            className="app-container container flex flex--column"
            style={{ marginTop: "5px!important" }}
          >
            <div
              style={{ textAlign: "center" }}
              className="app__data-container flex flex--column"
            >
              <h1 className="">
                Share and get paid
                <br /> instantly
              </h1>
              <span>
                Share your referral link and get paid instantly to your wallet
                for every referred SpaceShipEarth purchase.
              </span>
              <br />
              <p>Referral commission 5%</p>
              <br />
              {/* <p>
                Share your referral link or QR code and get commission for
                referred token purchases instantly to your wallet.
              </p> */}
              <br />
              <p>
                <input
                  type="text"
                  id="referLink"
                  value={referrallink}
                  readOnly={true}
                />
              </p>
              <CopyToClipboard
                text={`${base_url}${account}`}
                onCopy={() => {
                  setRefcopy(true);
                  setTimeout(() => {
                    setRefcopy(false);
                  }, 2000);
                }}
              >
                <button className="btn" id="copyreflink">
                  Copy
                </button>
              </CopyToClipboard>
              <span>{refcopy && "copied"}</span>
              <p id="refErr" className="err" style={{ display: "none" }}>
                Please connect your wallet on Binance Smart Chain to generate
                your referral link!
              </p>
            </div>
          </div>
        </section>

        <footer
          class="page-footer font-small cyan darken-3 text-center"
          style={{ backgroundColor: "#1A1A1A" }}
        >
          {/* <!-- Footer Elements --> */}
          <div class="container">
            {/* <!-- Grid row--> */}
            <div class="row">
              {/* <!-- Grid column --> */}
              <div class="col-md-12">
                {/* <div class="mb-2 mt-5 flex-center">
                  <a
                    href="https://instagram.com/frpot_314?r=nametag"
                    class="tw-ic"
                  >
                    <i class="fab fa-instagram fa-lg white-text mr-md-5 mr-3 fa-2x"></i>
                  </a>

                  <a
                    href="https://www.facebook.com/profile.php?id=100078534654052"
                    class="tw-ic"
                  >
                    <i class="fab fa-facebook fa-lg white-text mr-md-5 mr-3 fa-2x"></i>
                  </a>
                  <a
                    href="https://www.frpch.org/donate-frpot-crypto/"
                    class="tw-ic"
                  >
                    <i class="fab fa-firefox fa-lg white-text mr-md-5 mr-3 fa-2x"></i>
                  </a>
                </div> */}
              </div>
              {/* <!-- Grid column --> */}
            </div>
            {/* <!-- Grid row--> */}
          </div>
          {/* <!-- Footer Elements --> */}

          {/* <!-- Copyright --> */}
          <div class="footer-copyright   text-center py-3" style={{
              display: "flex",
              justifyContent: "space-between",
          }}>
            <p>© All Rights Reserved SpaceShipEarth</p>
            
              <div style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "15px",
                marginTop: "5px",
              }}>
                <img src={discord} alt="logo" style={{
                  width: "30px",
                  height: "30px",
                  marginRight: "10px",
                  
                  cursor: "pointer"

                }} onClick={()=>window.open("https://discord.gg/mcSK92d6", "_blank")} />
            
            
                <img src={twitter} alt="logo" style={{
                  width: "30px",
                  height: "30px",
                  marginRight: "10px",
                  cursor: "pointer"

                }} onClick={()=>window.open("https://twitter.com/SpaceshipearthT", "_blank")} />
                <img src={telegram} alt="logo" style={{
                  width: "30px",
                  height: "30px",
                  // marginRight: "10px",
                  cursor: "pointer"

                }} onClick={()=>window.open("https://t.me/+MSdvTr-RO8FiN2U0", "_blank")} />
              </div>
            
          </div>
          {/* <!-- Copyright --> */}
        </footer>
      </main>
    </div>
  );
}
